import ChatTitles from "./ChatTitles";
import ChatMessages from "./ChatMessages";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserChatHistory, getUserChat } from "./UserDataHelpers";
import { Box, CircularProgress, Grid } from "@mui/material";
import { setChatLoader } from "../../redux/slice/ChatSlice";

function ChatHistory({ userId, userName }) {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(setChatLoader(true));
      await fetchUserChatHistory(userId, 1);
    })();
  }, [userId, dispatch]);
  const userChat = useSelector((state) => state.ChatHistory.chatHistory);
  useEffect(() => {
    (async () => {
      if (userChat?.data?.length) {
        const chatId = userChat?.data[0]?.id;
        await getUserChat(chatId, userId);
      }
      dispatch(setChatLoader(false));
    })();
  }, [dispatch, userChat?.data, userId]);
  const chat = useSelector((state) => state.Chat.chat);
  const loader = useSelector((state) => state.Chat.chatLoader);
  if (chat?.data?.length && userChat?.data?.length && !loader)
    return (
      <Grid container sx={{ height: "100%" }} columnSpacing={3}>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            borderWidth: { xs: "0 0 1px 0", sm: "0 1px 0 0" },
            borderStyle: "solid",
            borderColor: "#f0f0f0",
            height: "100%",
          }}
        >
          <PerfectScrollbar>
            <ChatTitles userId={userId} />
          </PerfectScrollbar>
        </Grid>
        <Grid item xs={12} sm={9} sx={{ height: "100%", width: "100%" }}>
          <PerfectScrollbar>
            <Box
              id="scrollableDiv"
              style={{
                height: "100%",
                overflow: "auto",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <ChatMessages userId={userId} user={userName} />
            </Box>
          </PerfectScrollbar>
        </Grid>
      </Grid>
    );
  if (loader)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <b>No Data Available</b>
    </Box>
  );
}

export default ChatHistory;
