import { Box, Dialog, Grid, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChatHistory from "./ChatHistory";

const chatData = [
  {
    response_text:
      "Sure, here is a simple \"Hello World\" program in ReactJS:\n\n```\n// Import React\nimport React from 'react';\n\n// Define the component\nfunction HelloWorld() {\n  return (\n    <div>\n      <h1>Hello World!</h1>\n    </div>\n  );\n}\n\n// Export the component\nexport default HelloWorld;\n```\n\nThis code defines a component called `HelloWorld` that simply renders an `h1` element with the text \"Hello World!\". To use this component in your application, you would import it and then include it in the JSX of another component or directly in your `index.js` file. For example:\n\n```\nimport React from 'react';\nimport ReactDOM from 'react-dom';\nimport HelloWorld from './HelloWorld';\n\nReactDOM.render(\n  <HelloWorld />,\n  document.getElementById('root')\n);\n```\n\nThis code imports the `HelloWorld` component and renders it to the DOM inside an element with an `id` of \"root\". When you run this code, you should see the text \"Hello World!\" displayed on the page.",
    response_id: 3480,
    chat_id: 665,
    chat_type: "ACCENT",
    request_text: "react js hello world programe",
    request_id: 3479,
    sources: [
      {
        id: 3524,
        user_chat_histories_id: 3480,
        fileName: "practice management campaign.page",
        similarity_score: 0.762273192,
        text: 'HTML","showLargeHeader":false,"spacingSettings":{"margins":{"top":"-10","right":0,"bottom":0,"left":0},"padding":{"top":0,"right":0,"bottom":0,"left":0}},"className":"modules-widget-html","html":"<div style=\\"margin: 0px;\\"><img style=\\"max-width: 100%; height: 187px;\\" src=\\"https://n14.accent-technologies.com/webclient/_libraries/adl/download.ashx?out=cjson&amp;mode=dsf&amp;fh=3140_7Pjba0UmXHp0Ob2g084kVUMVMQYERqXFge-TcDouzsQ1\\"><br>\\n<div style=\\"padding: 20px;\\"><span style=\\"font-family: verdana, geneva, sans-serif; font-size: 12pt;\\"><span style=\\"font- family: verdana, geneva, sans-serif; font-size: 12pt;\\">Sales Plays<br></span></span><span style=\\"font-size: 8pt;\\">60 Minutes</span><span style=\\"font-size: 12pt; text-align: right;\\">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></div>\\n</div>","height":"300","allowOptionsDialog":true,"allowHeaderSelection":true},"type":"HtmlWidget"},{"id":"0b519bb5-6aa6-49c0-950e-18b39b8f2762","options":{"0":"<","1":"d","2":"i","3":"v","4":" ","5":"s","6":"t","7":"y","8":"l","9":"e","10":"=","11":"\\"","12":"m","13":"a","14":"r","15":"g","16":"i","17":"n","18":":","19":" ","20":"0","21":"p","22":"x","23":";","24":"\\"","25":">","26":"<","27":"i","28":"m","29":"g","30":" ","31":"s","32":"t","33":"y","34":"l","35":"e","36":"=","37":"\\"","38":"m","39":"a","40":"x","41":"-","42":"w","43":"i","44":"d","45":"t","46":"h","47":":","48":" ","49',
        created_at: "2023-06-09T10:27:21.000+00:00",
        updated_at: "2023-06-09T10:27:21.000+00:00",
      },
      {
        id: 3525,
        user_chat_histories_id: 3480,
        fileName: "_Accent Readiness Page.page",
        similarity_score: 0.762211442,
        text: 'HTML","showLargeHeader":false,"spacingSettings":{"margins":{"top":"-10","right":0,"bottom":0,"left":0},"padding":{"top":0,"right":0,"bottom":0,"left":0}},"className":"modules-widget-html","html":"<div style=\\"margin: 0px;\\"><img style=\\"max-width: 100%; height: 187px;\\" src=\\"https://n14.accent-technologies.com/webclient/_libraries/adl/download.ashx?out=cjson&amp;mode=dsf&amp;fh=3140_7Pjba0UmXHp0Ob2g084kVUMVMQYERqXFge-TcDouzsQ1\\"><br>\\n<div style=\\"padding: 20px;\\"><span style=\\"font-family: verdana, geneva, sans-serif; font-size: 12pt;\\"><span style=\\"font- family: verdana, geneva, sans-serif; font-size: 12pt;\\">Sales Plays<br></span></span><span style=\\"font-size: 8pt;\\">60 Minutes</span><span style=\\"font-size: 12pt; text-align: right;\\">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></div>\\n</div>","height":"300","allowOptionsDialog":true,"allowHeaderSelection":true},"type":"HtmlWidget"},{"id":"0b519bb5-6aa6-49c0-950e-18b39b8f2762","options":{"0":"<","1":"d","2":"i","3":"v","4":" ","5":"s","6":"t","7":"y","8":"l","9":"e","10":"=","11":"\\"","12":"m","13":"a","14":"r","15":"g","16":"i","17":"n","18":":","19":" ","20":"0","21":"p","22":"x","23":";","24":"\\"","25":">","26":"<","27":"i","28":"m","29":"g","30":" ","31":"s","32":"t","33":"y","34":"l","35":"e","36":"=","37":"\\"","38":"m","39":"a","40":"x","41":"-","42":"w","43":"i","44":"d","45":"t","46":"h","47":":","48":" ","49',
        created_at: "2023-06-09T10:27:21.000+00:00",
        updated_at: "2023-06-09T10:27:21.000+00:00",
      },
      {
        id: 3526,
        user_chat_histories_id: 3480,
        fileName: "_Accent Readiness Page.page",
        similarity_score: 0.761765718,
        text: 'style=\\"max-width: 100%; height: 187px;\\" src=\\"https://n14.accent-technologies.com/webclient/_libraries/adl/download.ashx?out=cjson&amp;mode=dsf&amp;fh=3140_7Pjba0UmXHp0Ob2g084kVaRLLowh8FFjUmWQFFraE8g1\\"><br>\\n<div style=\\"padding: 20px;\\"><span style=\\"font-family: verdana, geneva, sans-serif; font-size: 12pt;\\"><span style=\\"font-family: verdana, geneva, sans-serif; font-size: 12pt;\\">Accent Connect<br></span></span><span style=\\"font-size: 8pt;\\">45 Minutes</span><span style=\\"font-size: 12pt; text-align: right;\\">&nbsp; &nbsp; &nbsp; &nbsp ; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></div>\\n</div>","height":"300","allowOptionsDialog":true,"allowHeaderSelection":true},"type":"HtmlWidget"},{"id":"d1595990-669a-4a3d-85fe-f17f305d1d6f","options":{"0":"<","1":"d","2":"i","3":"v","4":" ","5":"s","6":"t","7":"y","8":"l","9":"e","10":"=","11":"\\"","12":"m","13":"a","14":"r","15":"g","16":"i","17":"n","18":":","19":" ","20":"0","21":"p","22":"x","23":";","24":"\\"","25":">","26":"<","27":"i","28":"m","29":"g","30":" ","31":"s","32":"t","33":"y","34":"l","35":"e","36":"=","37":"\\"","38":"m","39":"a","40":"x","41":"-","42":"w","43":"i","44":"d","45":"t","46":"h","47":":","48":" ","49":"1","50":"0","51":"0","52":"%","53":";","54":" ","55":"h","56":"e","57":"i","58":"g","59":"h","60":"t","61":":","62":" ","63":"1","64":"8","65":"7","66":"p","67',
        created_at: "2023-06-09T10:27:21.000+00:00",
        updated_at: "2023-06-09T10:27:21.000+00:00",
      },
    ],
  },
];
export default function ChatDialogue({ setDialogObj, data }) {
  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        sx: { height: "100%" },
      }}
    >
      <DialogTitle>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <CloseIcon
                onClick={setDialogObj}
                style={{
                  float: "right",
                  marginTop: 1,
                  marginBottom: 8,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ChatHistory
          chatData={chatData}
          userId={data.data.id}
          userName={data.data.name}
        />
      </DialogContent>
    </Dialog>
  );
}
