import styled from "@emotion/styled";
import {
  Grid,
  Paper as MuiPaper,
  Typography,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

const Paper = styled(MuiPaper)(spacing);

function CustomersInfo({ customer }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (customer) {
      setLoading(false);
    }
  }, [customer]);
  return (
    <PerfectScrollbar>
      <Paper>
        {loading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress m={2} color="secondary" />
          </Grid>
        ) : (
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h3" display="inline">
                User's Information
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography gutterBottom component="div">
                        Customer Name:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom component="div">
                        {customer.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography gutterBottom component="div">
                        Phone Number:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom component="div">
                        {customer.phone}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography gutterBottom component="div">
                        Message Count:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom component="div">
                        {customer.message_count}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography gutterBottom component="div">
                        Last Message Date:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom component="div">
                        {customer.last_message_date
                          ? `${customer.last_message_date.split("T")[0]} ${
                              customer.last_message_date
                                .split("T")[1]
                                .split(".")[0]
                            }`
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography gutterBottom component="div">
                        Status:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom component="div">
                        {customer.is_active ? (
                          <Typography sx={{ color: "success.main" }}>
                            Active
                          </Typography>
                        ) : (
                          <Typography sx={{ color: "error.main" }}>
                            Blocked
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Paper>
    </PerfectScrollbar>
  );
}
export default CustomersInfo;
