import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { deleteCustomerData } from "./CustomersHelpers";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useDispatch } from "react-redux";
import { setSnackBarAlert } from "../../redux/slice/AlertsSlice";

export default function DeleteConfirmationDialogue({ setDialogObj, data }) {
  const dispatch = useDispatch();
  const handleDelete = async () => {
    const deleteResponse = await deleteCustomerData(data.data);
    if (deleteResponse) {
      data.api.applyTransaction({ remove: [data.data] });
      setDialogObj();
      dispatch(
        setSnackBarAlert({
          severity: "success",
          message: "Customer Deleted Successfully",
        })
      );
    }
  };
  return (
    <Dialog open={true} onClose={setDialogObj}>
      <DialogTitle>{"Delete Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this User?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={setDialogObj}>No</Button>
        <Button onClick={handleDelete} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
