import THEME from "../../config/theme";
import { FileUploader } from "react-drag-drop-files";
import { memo } from "react";
import { Typography } from "@mui/material";

const FileUploaderComponent = ({
  handleChange,
  dummyFilePath,
  setFileError,
  fileError,
}) => {
  const fileTypes = ["PDF"];
  const maxSize = 5; // 5MB in bytes

  return (
    <>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        maxSize={maxSize}
        onTypeError={() =>
          setFileError(
            "File type is not supported. Only files with the following extensions are allowed: pdf"
          )
        }
        onSizeError={() => {
          setFileError("File size exceeds the maximum limit of 5MB.");
          console.log("error size");
        }}
        children={
          <>
            {!dummyFilePath ? (
              <div
                style={{
                  padding: "20% 50px",
                  backgroundColor: "#F9F9FB",
                  border: dummyFilePath
                    ? `1px solid ${THEME.COLORS.DARKGRAY}`
                    : `1px dashed ${THEME.COLORS.DARKGRAY}`,
                  borderRadius: 5,
                  height: "100%",
                }}
              >
                <Typography>
                  Drag and drop your file here, or{" "}
                  <span
                    style={{
                      color: THEME.COLORS.PRIMARY,
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    click here
                  </span>{" "}
                  to Upload <br /> Supported Formats: PDF. Max File Size: 5MB.
                  Do not upload password-protected files.
                </Typography>

                {fileError ? (
                  <Typography sx={{ color: "red" }}>{fileError}</Typography>
                ) : null}
              </div>
            ) : (
              <>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "52.25%",
                    height: 0,
                  }}
                >
                  <iframe
                    title="document"
                    src={dummyFilePath}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default memo(FileUploaderComponent);
