import React from "react";
import routes from "./routes";
import createTheme from "./theme";
import useTheme from "./hooks/useTheme";
import { useRoutes } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AuthProvider } from "./contexts/JWTContext";
import createEmotionCache from "./utils/createEmotionCache";
import { HelmetProvider, Helmet } from "react-helmet-async";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import NotificationAlerts from "./components/alerts/NotificationAlerts";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Chat With PDF"
          defaultTitle="Chat With PDF - Admin Dashboard"
        />
        <NotificationAlerts />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <AuthProvider>{content}</AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
