import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Save } from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Paper, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import MuiPhoneNumber from "mui-phone-number";
import { checkMobilePhoneValidity } from "../customers/CustomersHelpers";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { createOrganization, updateOrganization } from "./OrganizationApiCalls";
export default function OrganizationDialogue(props) {
  const { data, setDialogObj, dialogName } = props;
  const dispatch = useDispatch();
  const handleActive = () => {
    setDialogObj();
  };
  const initialValues = {
    name: get(data, "name", ""),
    address: get(data, "address", ""),
    phone: data?.phone ? data.phone : "",
    validPhoneNumber: data?.phone
      ? checkMobilePhoneValidity(data.phone)
      : false,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    validPhoneNumber: Yup.boolean(),
    phone: Yup.string().test(
      "checkValidity",
      "Valid Phone number is required",
      (phone, data) => {
        if (data.parent.validPhoneNumber) return true;
      }
    ),
  });

  const addOrganization = async (param) => {
    const { values, setErrors, setStatus, setSubmitting } = param;
    const response = await dispatch(createOrganization(values));
    if (response) {
      setSubmitting(false);
      // onCustomerUpdate(response);
      setStatus({ sent: true });
    } else {
      setStatus({ sent: false });
      const apiErrors = get(response, "message.messages.errors", []);
      if (apiErrors.length === 0) {
        apiErrors.push({ field: "Update", message: "Operation failed." });
      }
      setErrors({
        submit: apiErrors.map((e) => `[${e.field}] ${e.message}`),
      });
      setSubmitting(false);
    }
  };

  const editOrganization = async (param) => {
    const { values, setErrors, setStatus, setSubmitting } = param;
    const response = await dispatch(updateOrganization(values, data.id));
    if (response) {
      setSubmitting(false);
      // onCustomerUpdate(response);
      setStatus({ sent: true });
    } else {
      setStatus({ sent: false });
      const apiErrors = get(response, "message.messages.errors", []);
      if (apiErrors.length === 0) {
        apiErrors.push({ field: "Update", message: "Operation failed." });
      }
      setErrors({
        submit: apiErrors.map((e) => `[${e.field}] ${e.message}`),
      });
      setSubmitting(false);
    }
  };

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    if (data) {
      editOrganization({
        values,
        setSubmitting,
        resetForm,
        setErrors,
        setStatus,
      });
    } else {
      addOrganization({
        values,
        setSubmitting,
        resetForm,
        setErrors,
        setStatus,
      });
    }
    handleActive();
  };
  return (
    <Dialog open={true} onClose={handleActive} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={10}>
              {dialogName}
            </Grid>
            <Grid item xs={2}>
              <CloseIcon
                onClick={handleActive}
                style={{
                  float: "right",
                  marginTop: 1,
                  marginBottom: 8,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Paper elevation={0} sx={{ pt: 2 }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={5}
                        justifyContent="space-between"
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            name="name"
                            label="Organization Name*"
                            value={values.name === null ? "" : values.name}
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MuiPhoneNumber
                            defaultCountry={"pk"}
                            name="phone"
                            countryCodeEditable={false}
                            label="Phone Number*"
                            placeholder="Enter your phone number"
                            value={values.phone ?? ""}
                            error={
                              touched.phone ? Boolean(errors.phone) : false
                            }
                            fullWidth
                            helperText={
                              touched.phone && errors.phone
                                ? errors.phone
                                : null
                            }
                            onBlur={handleBlur}
                            variant="outlined"
                            onChange={(value, country) => {
                              setFieldValue("phone", value);
                              const isValidPhone =
                                checkMobilePhoneValidity(value);
                              if (isValidPhone) {
                                setFieldValue("validPhoneNumber", true);
                              } else {
                                setFieldValue("validPhoneNumber", false);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="address"
                        label="Organization Address*"
                        value={values.address === null ? " " : values.address}
                        error={Boolean(touched.address && errors.address)}
                        fullWidth
                        helperText={(touched.address && errors.address) ?? " "}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      loadingPosition="start"
                      startIcon={<Save />}
                      disabled={isSubmitting}
                      mt={3}
                    >
                      {dialogName}
                    </LoadingButton>
                  </Grid>
                </form>
              </>
            )}
          </Formik>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
