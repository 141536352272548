import axios from "../../utils/axios";
import { appConfig } from "../../config/config";
import moment from "moment";

export const getRequestLogsData = async (params) => {
  try {
    const APIResponse = await axios.get(
      `${appConfig.API_URL}/invites_listing`,
      {
        page: params.currentPage,
        page_size: 3,
      }
    );

    APIResponse.data.data = APIResponse.data.data.map((s) => ({
      ...s,
      requestDate: s.created_at
        ? moment(s.created_at).format("YYYY-MM-DD HH:mm:ss")
        : "",
      user: s.invitation_email,
      type: s.invitation_type,
      referralCode: s.referral_code,
    }));
    return APIResponse.data;
  } catch (e) {
    console.log("error", e);
    return [];
  }
};
