export const appConfig = {
  API_URL: process.env.REACT_APP_API_URL,
};
export const defaultPlan = {
  name: "Free Plan",
  price: 0,
};
export const SubscriptionStatus = {
  0: "Pending",
  1: "In Progress",
  2: "Paused",
  3: "Completed",
  4: "Deleted",
};
export const perPageItems = [
  {
    value: 25,
    valueToshow: 25,
  },
  {
    value: 50,
    valueToshow: 50,
  },
  {
    value: 100,
    valueToshow: 100,
  },
  {
    value: 200,
    valueToshow: 200,
  },
];
export const plans = [
  { id: 1, value: "Free Plan", valueToshow: "Free Plan" },
  { id: 2, value: "Pro Plan", valueToshow: "Pro Plan" },
  { id: 3, value: "Pro Plan - EN", valueToshow: "Pro Plan - EN" },
  { id: 4, value: "Pro Plan - EU", valueToshow: "Pro Plan - EU" },
  { id: 5, value: "Pro Plan - BR/PT", valueToshow: "Pro Plan - BR/PT" },
  { id: 6, value: "Pro Plan - FR", valueToshow: "Pro Plan - FR" },
];
export const dataFetchType = {
  filter: "FILTER",
  export: "EXPORT",
};
export const localStorageKeys = {
  perPage: "USER_PER_PAGE_SELECTION",
  authToken: "ACCESSTOKEN",
  userObj: "USER",
  customTheme: "THEME",
};
export const statusNameAndColor = {
  accepted: "success.main",
  active: "success.main",
  declined: "error.main",
  blocked: "error.main",
};
