import React, { useState } from "react";
import {
  RemoveRedEye as RemoveRedEyeIcon,
  Edit,
  ChatBubbleOutline,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Typography } from "@mui/material";
import { statusNameAndColor } from "../../config/config";
import DeleteConfirmationDialogue from "./DeleteConfirmationDialogue";
import CustomerDetails from "./CustomerDetails";
import EditCustomerDialog from "./EditCustomerDialog";
import ChatDialogue from "../chat/ChatDialog";

const stylesInlineTypographTable = {
  height: "100%",
  alignItems: "center",
  display: "flex",
  fontSize: "14px",
};

export const ActionButtons = (params) => {
  const [dialogObj, setDialogObj] = useState(null);
  const dialogType = {
    delete: "DELETE",
    update: "UPDATE",
    view: "VIEW",
    chat: "CHAT",
  };
  return (
    <Box sx={{ display: "inline" }}>
      <IconButton
        onClick={() => setDialogObj(dialogType.view)}
        aria-label="details"
        size="small"
      >
        <RemoveRedEyeIcon />
      </IconButton>
      <IconButton
        onClick={() => setDialogObj(dialogType.update)}
        aria-label="edit"
        size="small"
      >
        <Edit />
      </IconButton>
      <IconButton
        onClick={() => setDialogObj(dialogType.chat)}
        aria-label="chat"
        size="small"
      >
        <ChatBubbleOutline />
      </IconButton>
      <IconButton
        onClick={() => setDialogObj(dialogType.delete)}
        aria-label="delete"
        size="small"
      >
        <DeleteIcon />
      </IconButton>
      {dialogObj === dialogType.delete ? (
        <DeleteConfirmationDialogue
          data={params}
          setDialogObj={() => setDialogObj(null)}
        />
      ) : dialogObj === dialogType.view ? (
        <CustomerDetails
          setDialogObj={() => setDialogObj(null)}
          data={params}
        />
      ) : dialogObj === dialogType.update ? (
        <EditCustomerDialog
          setDialogObj={() => setDialogObj(null)}
          data={params}
        />
      ) : dialogObj === dialogType.chat ? (
        <ChatDialogue setDialogObj={() => setDialogObj(null)} data={params} />
      ) : null}
    </Box>
  );
};

export const RenderStatusChip = ({ value }) => {
  return (
    <Typography
      sx={{ color: statusNameAndColor[value.toLowerCase()] }}
      style={stylesInlineTypographTable}
    >
      {value}
    </Typography>
  );
};
