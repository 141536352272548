import axios from "./axios";
import { localStorageKeys } from "../config/config";

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//   }, timeLeft);
// };

const setSession = (accessToken, user) => {
  if (accessToken) {
    localStorage.setItem(localStorageKeys.authToken, accessToken);
    localStorage.setItem(localStorageKeys.userObj, JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem(localStorageKeys.authToken);
    localStorage.removeItem(localStorageKeys.userObj);
    delete axios.defaults.headers.common.Authorization;
  }
};

export { setSession };
