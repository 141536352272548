import React, { useState } from "react";
import { RemoveRedEye as RemoveRedEyeIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Typography } from "@mui/material";
import { statusNameAndColor } from "../../config/config";
import DeleteConfirmationDialogue from "./DeleteConfirmationDialogue";
import DocumentPreview from "./DocumentPreview";

const stylesInlineTypographTable = {
  height: "100%",
  alignItems: "center",
  display: "flex",
  fontSize: "14px",
};

export const ActionButtons = (params) => {
  const [dialogObj, setDialogObj] = useState(null);
  const dialogType = {
    delete: "DELETE",
    view: "VIEW",
  };
  return (
    <>
      <Box sx={{ display: "inline" }}>
        <IconButton
          onClick={() => setDialogObj(dialogType.view)}
          aria-label="details"
          size="small"
        >
          <RemoveRedEyeIcon />
        </IconButton>
        <IconButton
          onClick={() => setDialogObj(dialogType.delete)}
          aria-label="delete"
          size="small"
        >
          <DeleteIcon />
        </IconButton>
        {dialogObj === dialogType.delete ? (
          <DeleteConfirmationDialogue
            data={params}
            setDialogObj={() => setDialogObj(null)}
          />
        ) : dialogObj === dialogType.view ? (
          <DocumentPreview
            data={params.data.urlPath}
            setDialogObj={() => setDialogObj(null)}
          />
        ) : null}
      </Box>
    </>
  );
};

export const RenderStatusChip = ({ data }) => {
  return (
    <Typography
      sx={{ color: statusNameAndColor[data.status.toLowerCase()] }}
      style={stylesInlineTypographTable}
    >
      {data.status}
    </Typography>
  );
};
