import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import hintTypesReducer from "./slice/hintTypesSlice";
import AlertsSlice from "./slice/AlertsSlice";
import HelpersSlice from "./slice/HelpersSlice";
import ChatHistoryReducer from "./slice/ChatHistorySlice";
import ChatReducer from "./slice/ChatSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    hintTypes: hintTypesReducer,
    Alerts: AlertsSlice,
    Helpers: HelpersSlice,
    ChatHistory: ChatHistoryReducer,
    Chat: ChatReducer,
  },
});

export const { dispatch, getState } = store;

export default store;
