import { memo, useMemo } from "react";
import { Button, TextField, Grid, Stack } from "@mui/material";
import { dataFetchType } from "../../config/config";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { every, isEmpty, values, get } from "lodash";

const Filters = ({
  searchFilters,
  setSearchFilters,
  getCustomers,
  pageSize,
  searchFiltersForPayload,
  setSearchFiltersForPayload,
  setCurrentPage,
}) => {
  const isAnyFilter = useMemo(() => {
    const startDateFromFilter = get(searchFilters, "startDate", null);
    const endDateFromFilter = get(searchFilters, "endDate", null);
    let isAllValuesNull = every(values(searchFilters), (value) =>
      isEmpty(value)
    );
    if (startDateFromFilter || endDateFromFilter) {
      isAllValuesNull = true;
      if (startDateFromFilter && endDateFromFilter) {
        isAllValuesNull = false;
      }
    }
    if (isAllValuesNull && !isEmpty(searchFiltersForPayload)) {
      (async () => {
        await getCustomers({
          currentPage: parseInt("1"),
          pageSize: parseInt(pageSize),
          searchFilters,
          type: dataFetchType.filter,
        });
        setSearchFiltersForPayload(searchFilters);
      })();
    }
    return !isAllValuesNull;
  }, [
    searchFilters,
    getCustomers,
    pageSize,
    searchFiltersForPayload,
    setSearchFiltersForPayload,
  ]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={12} md={10}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              name="customerName"
              fullWidth
              label="Name"
              size="medium"
              value={searchFilters.customerName}
              onChange={(e) =>
                setSearchFilters({
                  ...searchFilters,
                  customerName: e.target.value.trimStart(),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="phoneNo"
              fullWidth
              label="Phone No#"
              size="medium"
              value={searchFilters.contactNo}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  setSearchFilters({
                    ...searchFilters,
                    contactNo: e.target.value.replace(/[^0-9]/g, ""),
                  });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Button
            fullWidth
            variant="contained"
            disabled={!isAnyFilter}
            size="large"
            onClick={async () => {
              await getCustomers({
                currentPage: parseInt("1"),
                pageSize: parseInt(pageSize),
                searchFilters,
                type: dataFetchType.filter,
              });
              setCurrentPage(1);
              setSearchFiltersForPayload(searchFilters);
            }}
          >
            Apply
          </Button>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            onClick={async () => {
              if (!isEmpty(searchFiltersForPayload)) {
                await getCustomers({
                  currentPage: parseInt("1"),
                  pageSize: parseInt(pageSize),
                  searchFilters: {},
                  type: dataFetchType.filter,
                });
                setCurrentPage(1);
                setSearchFiltersForPayload({});
              }
              setSearchFilters({
                customerName: "",
                plan: "",
                contactNo: "",
                startDate: null,
                endDate: null,
              });
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default memo(Filters);
