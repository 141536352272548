import { Helmet } from "react-helmet-async";
import { Grid } from "@mui/material";
import SettingForm from "./SettingForm";

const Settings = () => {
  return (
    <>
      <Helmet title="Settings" />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
        mt={5}
      >
        <Grid item xs={12} md={10}>
          <SettingForm />
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;
