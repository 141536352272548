import { Box, CircularProgress } from "@mui/material";
import QueryBox from "./QueryBox";
import ResponseBox from "./ResponseBox";
import { useState } from "react";
import { getUserChat } from "./UserDataHelpers";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

const ChatMessages = ({ user, userId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const chatData = useSelector((state) => state.Chat.chat);

  const loadMoreFunction = async (chatId, page) => {
    setCurrentPage(page);
    await getUserChat(chatId, userId, page);
  };

  const checkLimit = () => {
    let value = chatData?.total_count / 2;
    if (chatData?.data?.length === value) {
      return false;
    }
    return true;
  };

  return (
    <InfiniteScroll
      dataLength={chatData.total_count / 2}
      next={() => loadMoreFunction(chatData.data[0].chat_id, currentPage + 1)}
      inverse={true}
      style={{ display: "flex", flexDirection: "column-reverse" }}
      hasMore={checkLimit()}
      loader={<CircularProgress />}
      initialScrollY={800}
      scrollableTarget="scrollableDiv"
    >
      {chatData?.data?.map((singleChat, index) => (
        <Box key={index}>
          {singleChat?.response_text && (
            <>
              <QueryBox
                firstUpperCaseLetter={user?.charAt(0)?.toLocaleUpperCase()}
                requestText={singleChat.request_text}
              />
              <ResponseBox chat={singleChat} sources={singleChat.sources} />
            </>
          )}
        </Box>
      ))}
    </InfiniteScroll>
  );
};

export default ChatMessages;
