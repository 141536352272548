import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";

export default function DocumentPreview(props) {
  const { data, setDialogObj } = props;
  const [open, setOpen] = useState(true);

  const handleActive = () => {
    setOpen(false);
    setDialogObj();
  };

  return (
    <Dialog open={open} onClose={handleActive} fullWidth maxWidth="md">
      <DialogTitle>Document Preview</DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={12}>
            <div
              style={{
                position: "relative",
                paddingBottom: "52.25%",
                height: 0,
              }}
            >
              <iframe
                title="document"
                src={data ?? null}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
