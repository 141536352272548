import { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { Helmet } from "react-helmet-async";
import useDrivePicker from "react-google-drive-picker";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { perPageItems } from "../../config/config";
import DocumentFilters from "./DocumentFilters";
import DocumentDialogue from "./DocumentDialogue";
import { DocumentTable } from "./DocumentTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentListings,
  sendGdriveCredentials,
} from "./DocumentsApiCalls";
import CustomPagination from "../../components/pagination";
// import { GoogleApiProvider } from "react-gapi";

const Documents = () => {
  const dispatch = useDispatch();
  const [openPicker, data, authResponse] = useDrivePicker();
  const [documentListing, setDocumentListing] = useState();
  const [pageSize, setPageSize] = useState(25);
  const [dummyFilePath, setDummyFilePath] = useState(null);
  const [file, setFile] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [fileError, setFileError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [gDriveDataObject, setGDriveDataObject] = useState(null);
  const [searchFilters, setSearchFilters] = useState({
    documentName: "",
  });
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const activeRerender = useSelector((state) => state.Helpers.activeRerender);
  const paginationPageData = useSelector(
    (state) => state.Helpers.paginationPageData
  );

  useEffect(() => {
    (async () => {
      const response = await dispatch(getDocumentListings(1, 25, null, null));
      setPageSize(25);
      setCurrentPage(1);
      setDocumentListing(response);
    })();
  }, [dispatch, activeRerender]);

  const handleDocumentUpload = async (file) => {
    setDummyFilePath(URL.createObjectURL(file));
    setFile(file);
  };
  const handleApplyFilters = async (selectedPageSize) => {
    const response = await dispatch(
      getDocumentListings(1, selectedPageSize ?? pageSize, searchFilters, null)
    );
    setIsFilterApplied(true);
    setCurrentPage(1);
    if (selectedPageSize) {
      setPageSize(selectedPageSize);
    }
    setDocumentListing(response);
  };

  const handleCancelFilters = async () => {
    if (isFilterApplied) {
      const response = await dispatch(
        getDocumentListings(1, pageSize, null, null)
      );
      setDocumentListing(response);
      setIsFilterApplied(false);
      setCurrentPage(1);
    }
  };

  const changePage = async (value) => {
    if (value !== currentPage) {
      const response = await dispatch(
        getDocumentListings(
          value,
          pageSize,
          isFilterApplied ? searchFilters : null,
          null
        )
      );
      setDocumentListing(response);
      setCurrentPage(value);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const checkGoogleAuth = () => {
    const now = Date.now();
    const gPickerData = JSON.parse(localStorage.getItem("googlePickerData"));
    if (gPickerData) {
      const { expires_in } = gPickerData;
      const expiresInMilliseconds = expires_in * 1000;
      if (now >= gPickerData.receivedAt + expiresInMilliseconds) {
        localStorage.removeItem("googlePickerData");
        localStorage.removeItem("googleToken");
      }
    }
  };

  const handleOpenPicker = () => {
    checkGoogleAuth();
    openPicker({
      clientId: process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID,
      developerKey: "AIzaSyBoqkdk7BsGRotzmfBKk8rdjwWGJsTLySg",
      setIncludeFolders: true,
      token: localStorage.getItem("googleToken") ?? null,
      setSelectFolderEnabled: true,
      multiselect: true,
      viewMode: "list",
      callbackFunction: (data) => {
        // if (data.action === "cancel") {
        //   console.log("User clicked cancel/close button");
        // }
        if (data.action === "picked") {
          const fileObject = data.docs[0];

          setGDriveDataObject(fileObject);
        }
      },
    });
  };

  const handleGDriveUpload = useCallback(
    async (fileObject) => {
      await dispatch(
        sendGdriveCredentials(
          fileObject.id,
          localStorage.getItem("googleToken"),
          fileObject.name,
          fileObject.mimeType
        )
      );
      const response = await dispatch(getDocumentListings(1, 25, null, null));
      setDocumentListing(response);
    },
    [dispatch]
  );

  useEffect(() => {
    const now = Date.now();
    const pickerdata = localStorage.getItem("googlePickerData");
    const token = localStorage.getItem("googleToken");
    if (data && !pickerdata && !token) {
      localStorage.setItem("googleToken", data.access_token);
      const newData = { ...data, receivedAt: now };
      localStorage.setItem("googlePickerData", JSON.stringify(newData));
    }
    checkGoogleAuth();
  }, [data]);

  useEffect(() => {
    if (gDriveDataObject) {
      handleGDriveUpload(gDriveDataObject);
    }
  }, [dispatch, gDriveDataObject, handleGDriveUpload]);

  return (
    <>
      <Helmet title="Documents" />
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12} md={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={{
              xs: 3,
              sm: 4,
              lg: 5,
            }}
          >
            <FormControl size="medium" fullWidth sx={{ minWidth: 86 }}>
              <InputLabel id="demo-simple-select-label">Per Page</InputLabel>
              <Select
                value={pageSize}
                label="Per Page:"
                onChange={(e) => handleApplyFilters(e.target.value)}
              >
                {perPageItems.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {`${s.valueToshow}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={10}>
          {/* <GoogleApiProvider
            clientId={
              "589452892328-dnugl4u3nceihjb5q50cbel4pd8dd1uj.apps.googleusercontent.com"
            }
          > */}
          <DocumentFilters
            fileError={fileError}
            setOpen={setOpenDialogue}
            openDrive={() => handleOpenPicker()}
            setCurrentPage={setCurrentPage}
            setSearchFilters={setSearchFilters}
            handleApplyFilters={handleApplyFilters}
            searchFilters={searchFilters}
            handleCancelFilters={handleCancelFilters}
          />
          {/* </GoogleApiProvider> */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <DocumentTable rows={documentListing} />
          <Grid item xs={12}>
            <CustomPagination
              count={paginationPageData?.totalPageCount}
              changePage={changePage}
              page={currentPage}
            />
          </Grid>
          {openDialogue && (
            <DocumentDialogue
              open={openDialogue}
              setOpen={setOpenDialogue}
              fileError={fileError}
              handleDocumentUpload={handleDocumentUpload}
              dummyFilePath={dummyFilePath}
              setFileError={setFileError}
              file={file}
              setFile={setFile}
              setDummyFilePath={setDummyFilePath}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Documents;
