import { isEmpty } from "lodash";
import axios from "../../utils/axios";
import { getState, dispatch } from "../../redux/store";
import { setUserChatHistory } from "../../redux/slice/ChatHistorySlice";
import { setChat } from "../../redux/slice/ChatSlice";

export const fetchUserChatHistory = async (
  userId,
  page = 1,
  page_size = 10
) => {
  const prevState = getState().ChatHistory.chatHistory;
  const body = JSON.stringify({
    page,
    page_size,
  });

  try {
    const response = await axios.post(`/admin/chats/${userId}`, body);
    if (!isEmpty(response?.data) && response?.status === 200) {
      const resData = {
        page: response.data.page,
        totalPageCount: response.data.total_page_count ?? 0,
        data: response.data.data?.map((d) => ({
          id: d.id,
          title: d.message,
        })),
      };
      if (!isEmpty(prevState) && page !== 1) {
        resData.data = [...prevState.data, ...resData.data];
      }
      dispatch(setUserChatHistory(resData));
    }
  } catch (error) {
    return null;
  }
};

export const getUserChat = async (chatId, userId, page = 1, page_size = 10) => {
  const prevState = getState().Chat.chat;
  const body = JSON.stringify({
    page,
    page_size,
    user_id: userId,
  });
  try {
    const response = await axios.post(
      `/admin/chats/get_chat_history/${chatId}`,
      body
    );
    if (!isEmpty(response?.data) && response?.status === 200) {
      const resData = response?.data;
      if (!isEmpty(prevState) && page !== 1) {
        resData.data = [...prevState.data, ...resData.data];
      }
      dispatch(setChat(resData));
    }
  } catch (error) {
    return null;
  }
};
