import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { ChatBubbleOutline } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { fetchUserChatHistory, getUserChat } from "./UserDataHelpers";
// import { setChatLoader } from "../../redux/slice/ChatHistorySlice";

function ChatTitles({ userId }) {
  const [loader, setLoader] = useState(false);
  // const dispatch = useDispatch();
  const [hoverFieldId, setHoverFieldId] = useState(null);
  const [activeFieldId, setActiveFieldId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const showMoreHandler = async (page) => {
    setCurrentPage(page);
    setLoader(true);
    await fetchUserChatHistory(userId, page);
    setLoader(false);
  };
  const showMessages = async (chatId) => {
    setActiveFieldId(chatId);
    await getUserChat(chatId, userId);
  };
  const userChat = useSelector((state) => state.ChatHistory.chatHistory);
  useMemo(() => {
    if (userChat?.data.length) {
      const chatId = userChat.data[0].id;
      setActiveFieldId(chatId);
    }
  }, [userChat]);

  return (
    <Box>
      {loader ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "50vh" }}
        >
          <CircularProgress size="20px" />
        </Box>
      ) : userChat?.data.length ? (
        <>
          {userChat?.data.map((chat, index) => {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                key={index}
                spacing={2}
                onMouseEnter={() => setHoverFieldId(chat.id)}
                onClick={() => showMessages(chat.id)}
                onMouseLeave={() => setHoverFieldId(null)}
                sx={{
                  p: "10px",
                  bgcolor:
                    hoverFieldId === chat.id || activeFieldId === chat.id
                      ? "#7e7e7e"
                      : "#f0f0f0",
                  border: "solid 1px #f0f0f0",
                  borderRadius: 1,
                  cursor: "pointer",
                  m: 1,
                }}
              >
                <ChatBubbleOutline
                  sx={{
                    color:
                      hoverFieldId === chat.id || activeFieldId === chat.id
                        ? "white"
                        : "#7e7e7e",
                    fontSize: 18,
                  }}
                />

                <Typography
                  variant="body1"
                  sx={{
                    color:
                      hoverFieldId === chat.id || activeFieldId === chat.id
                        ? "white"
                        : "#7e7e7e",
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {chat.title}
                </Typography>
              </Stack>
            );
          })}
          {userChat.totalPageCount !== currentPage && (
            <div style={{ textAlign: "center", marginBottom: 5 }}>
              <LoadingButton
                loading={loader}
                size="small"
                onClick={() => showMoreHandler(currentPage + 1)}
              >
                show more
              </LoadingButton>
            </div>
          )}
        </>
      ) : (
        <Typography sx={{ textAlign: "center" }}>No data found!</Typography>
      )}
    </Box>
  );
}

export default ChatTitles;
