import { setSnackBarAlert } from "../../redux/slice/AlertsSlice";
import axios from "../../utils/axios";
import { errorMessageHandler } from "../../utils/helpers";
import {
  setActiveRerender,
  setPaginationPageData,
} from "../../redux/slice/HelpersSlice";
import { setLoader } from "../../redux/slice/authSlice";

export const getOrganizationListing = (currentPage, pageSize, signal) => {
  return async (dispatch) => {
    try {
      const payload = {
        page: currentPage,
        page_size: pageSize,
      };
      dispatch(setLoader(true));
      const { data: response } = await axios.post(
        `/organizations/list`,
        payload,
        {
          ...(signal && { signal }),
        }
      );
      dispatch(setLoader(false));
      if (response?.data) {
        const formattedData = response.data.map((organization) => {
          return {
            id: organization.id,
            name: organization.name,
            phone: organization.phone,
            address: organization.address,
          };
        });
        const paginationPageData = {
          totalPageCount: response.total_page_count ?? 0,
          totalCount: response.total_count ?? 0,
          pageSize: response.page_size,
          pageNo: response.page,
        };
        dispatch(setPaginationPageData(paginationPageData));
        return formattedData;
      } else {
        dispatch(
          setSnackBarAlert({
            severity: "error",
            message: errorMessageHandler(response),
          })
        );
        return false;
      }
    } catch (error) {
      if (error.code !== "ERR_CANCELED") {
        console.log("error", error);
        dispatch(
          setSnackBarAlert({
            message: "Something went wrong!",
            severity: "error",
          })
        );
        return false;
      }
    }
  };
};

export const updateOrganization = (body, id) => {
  return async (dispatch) => {
    try {
      const payload = JSON.stringify(body);
      dispatch(setLoader(true));
      const { data: response } = await axios.put(
        `/organizations/${id}`,
        payload
      );
      dispatch(setLoader(false));
      dispatch(setActiveRerender());
      if (response?.status) {
        dispatch(
          setSnackBarAlert({
            severity: "success",
            message: "Organization Updated Successfully",
          })
        );
      } else {
        dispatch(
          setSnackBarAlert({
            severity: "error",
            message: errorMessageHandler(response),
          })
        );
        return false;
      }
    } catch (error) {
      if (error.code !== "ERR_CANCELED") {
        console.log("error", error);
        dispatch(
          setSnackBarAlert({
            message: "Something went wrong!",
            severity: "error",
          })
        );
        return false;
      }
    }
  };
};

export const createOrganization = (body) => {
  return async (dispatch) => {
    try {
      const payload = JSON.stringify(body);
      dispatch(setLoader(true));
      const { data: response } = await axios.post(`/organizations/`, payload);
      dispatch(setLoader(false));
      dispatch(setActiveRerender());
      if (response?.status) {
        dispatch(
          setSnackBarAlert({
            severity: "success",
            message: "Organization Created Successfully",
          })
        );
      } else {
        dispatch(
          setSnackBarAlert({
            severity: "error",
            message: errorMessageHandler(response),
          })
        );
        return false;
      }
    } catch (error) {
      if (error.code !== "ERR_CANCELED") {
        console.log("error", error);
        dispatch(
          setSnackBarAlert({
            message: "Something went wrong!",
            severity: "error",
          })
        );
        return false;
      }
    }
  };
};

export const assignOrganizationAdmin = (body, id) => {
  return async (dispatch) => {
    try {
      const payload = JSON.stringify({ ...body, organization_id: id });
      dispatch(setLoader(true));
      const { data: response } = await axios.post(
        `/organizations/create_organization_user`,
        payload
      );
      dispatch(setLoader(false));
      dispatch(setActiveRerender());
      if (response?.status) {
        dispatch(
          setSnackBarAlert({
            severity: "success",
            message: "Organization Admin Created Successfully",
          })
        );
      } else {
        dispatch(
          setSnackBarAlert({
            severity: "error",
            message: errorMessageHandler(response),
          })
        );
        return false;
      }
    } catch (error) {
      if (error.code !== "ERR_CANCELED") {
        dispatch(
          setSnackBarAlert({
            message: "Something went wrong!",
            severity: "error",
          })
        );
        return false;
      }
    }
  };
};

export const deleteOrganization = (id) => {
  return async (dispatch) => {
    try {
      const { data: response } = await axios.delete(`/organizations/${id}`);
      if (response?.status) {
        dispatch(
          setSnackBarAlert({
            severity: "success",
            message: "Organization Deleted Successfully",
          })
        );
        return true;
      } else {
        dispatch(
          setSnackBarAlert({
            severity: "error",
            message: errorMessageHandler(response),
          })
        );
      }
    } catch (error) {
      console.log("error", error);
      dispatch(
        setSnackBarAlert({
          message: "Something went wrong!",
          severity: "error",
        })
      );
    }
  };
};
