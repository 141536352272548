import {
  Box,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet-async";
import UserInformationForm from "./UserInformationForm";
import { useDispatch } from "react-redux";
import { setLoader } from "../../redux/slice/authSlice";
import { modifyCustomerData } from "./CustomersHelpers";

export default function EditCustomerDialog({ setDialogObj, data }) {
  const dispatch = useDispatch();
  const handleEditCustomer = (customer) => {
    dispatch(setLoader(true));
    const instance = modifyCustomerData(customer.data);
    const gridApi = data.api;
    const rowNode = gridApi.getRowNode(data.node.childIndex);
    rowNode.setData(instance);
    gridApi.refreshCells();
    dispatch(setLoader(false));
  };
  return (
    <PerfectScrollbar>
      <Dialog open={true} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={10}></Grid>
              <Grid item xs={2}>
                <CloseIcon
                  onClick={setDialogObj}
                  style={{
                    float: "right",
                    marginTop: 1,
                    marginBottom: 8,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <Helmet title="Edit User" />
            <Typography variant="h3" gutterBottom display="inline">
              Edit User's Information
            </Typography>
            <UserInformationForm
              data={data.data}
              onCustomerUpdate={handleEditCustomer}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </PerfectScrollbar>
  );
}
