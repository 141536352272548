import React, { useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { documentsTableColumnDefs } from "../../utils/constants";
import { ActionButtons, RenderStatusChip } from "./FrameworkComponents";
import { Grid, styled } from "@mui/material";

export function DocumentTable({ rows }) {
  const agGridRef = useRef();
  useEffect(() => {
    const agGridDiv =
      agGridRef.current?.gridOptionsWrapper?.getLayoutElements()[1];
    new PerfectScrollbar(agGridDiv);
  }, []);
  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true,
  };
  const frameworkComponents = {
    ActionButtons,
    RenderStatusChip,
  };

  return (
    <div style={{ margin: "10px 0" }}>
      <StyledGrid>
        <div className="ag-theme-alpine">
          <AgGridReact
            ref={agGridRef}
            rowData={rows}
            // domLayout="autoHeight"
            defaultColDef={defaultColDef}
            columnDefs={documentsTableColumnDefs}
            components={frameworkComponents}
            suppressScrollOnNewData={true}
          ></AgGridReact>
        </div>
      </StyledGrid>
    </div>
  );
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  "& .ag-root-wrapper": {
    height: "72vh",
    [theme.breakpoints.up("xl")]: {
      height: "81vh",
    },
    [theme.breakpoints.down("md")]: {
      height: "58vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "43vh",
    },
  },
}));
