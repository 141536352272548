import * as React from "react";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FileUploaderComponent from "./FileUploader";
import { Button, Grid, Stack, useMediaQuery } from "@mui/material";
import THEME from "../../config/theme";
import { uploadDocument } from "./DocumentsApiCalls";
import { useDispatch } from "react-redux";

export default function DocumentDialogue(props) {
  const dispatch = useDispatch();
  const {
    open,
    setOpen,
    handleDocumentUpload,
    dummyFilePath,
    file,
    setFileError,
    setFile,
    setDummyFilePath,
    fileError,
  } = props;
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const handleActive = () => {
    setFileError(null);
    setOpen(false);
    setFile(null);
    setDummyFilePath(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleActive}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: { height: largeScreen ? "60%" : "65%" },
      }}
    >
      <DialogTitle>Upload Document</DialogTitle>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={12}>
            <FileUploaderComponent
              handleChange={(dummyFilePath) =>
                handleDocumentUpload(dummyFilePath)
              }
              fileError={fileError}
              dummyFilePath={dummyFilePath}
              // setFileError={setFileError}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" my={2}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: THEME.COLORS.PRIMARY,
                }}
                disabled={!file}
                onClick={() => {
                  dispatch(uploadDocument(file));
                  setOpen(false);
                  setFile(null);
                  setDummyFilePath(null);
                }}
              >
                Upload
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
