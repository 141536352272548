import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import {
  percentplaceholders,
  percenttext,
  placeholders,
  valuetext,
  defaultSettingsKeys,
} from "../../utils/constants";
import axios from "../../utils/axios";

const SettingForm = () => {
  const [source, setSource] = useState(null);
  const [score, setScore] = useState(null);
  const [description, setDescription] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateSettings = async (body) => {
    setLoading(true);
    try {
      const { data: response } = await axios.patch(
        `users/settings/update`,
        body
      );
      if (response.status) {
        // eslint-disable-next-line array-callback-return
        getSettings();
      } else {
        // toast.error(response.message);
      }
    } catch (err) {
      // toast.error("Something Went Wrong!");
    } finally {
      setLoading(false);
    }
  };

  const getSettings = async () => {
    try {
      const { data: response } = await axios.get(`users/settings/get`);
      if (response.status) {
        // eslint-disable-next-line array-callback-return
        response.data.map((res) => {
          if (res.key === defaultSettingsKeys.SOURCE_LIMIT)
            setSource(Number(res.value));
          if (res.key === defaultSettingsKeys.SIMILARITY_SCORE) {
            const percentage = Number(res.value) * Number(100);
            setScore(percentage);
          }
          if (res.key === defaultSettingsKeys.PERSONALITY_PROMPT)
            setDescription(res.value);
        });
      } else {
        // toast.error(response.message);
      }
    } catch (err) {
      // toast.error("Something");
    } finally {
      // console.log(source, score);
    }
  };
  const SubmitForm = (e) => {
    e.preventDefault();
    const scoreSetting = score / 100;
    const body = JSON.stringify({
      settings: [
        { key: defaultSettingsKeys.SOURCE_LIMIT, value: source },
        { key: defaultSettingsKeys.SIMILARITY_SCORE, value: scoreSetting },
        { key: defaultSettingsKeys.PERSONALITY_PROMPT, value: description },
      ],
    });
    updateSettings(body);
  };

  const handleLaunch = () => {
    const url =
      "https://lo-n02.accent-technologies.com/webclient/apps/admin/manager.htm?libName=sales2";
    window.open(url, "_blank");
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Box component="form" onSubmit={SubmitForm}>
      <Typography variant="h4">Knowledge Base</Typography>
      <Typography variant="h6" pt={3}>
        Max Sources
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "14px" }}>
        The maximum number of knowledge base documents considered per chat
      </Typography>
      <Box sx={{ px: 4 }}>
        <Slider
          id="sources"
          name="sources"
          value={source ?? source}
          getAriaValueText={valuetext}
          step={1}
          min={1}
          max={5}
          valueLabelDisplay="auto"
          onChange={(e) => setSource(e.target.value)}
          marks={placeholders}
        />
      </Box>
      <Typography variant="h6" pt={3}>
        Similarity Score
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "14px" }}>
        How relevant does a knowledge base documents have to be used in the
        model's response?
      </Typography>
      <Box variant="div" sx={{ px: 4 }}>
        <Slider
          id="score"
          name="score"
          value={score ?? score}
          getAriaValueText={percenttext}
          onChange={(e) => setScore(e.target.value)}
          valueLabelDisplay="auto"
          marks={percentplaceholders}
        />
      </Box>
      <Typography variant="h6" pt={3}>
        Manage Content
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "14px", mb: 3 }}>
        Manage the content used for your Accent Assist model
      </Typography>

      <Button variant="contained" onClick={handleLaunch}>
        Launch Content Manager
      </Button>
      <Divider sx={{ mt: 3 }} />
      <Typography variant="h4" pt={3}>
        Personality
      </Typography>
      <Typography variant="h6" pt={3}>
        System Personality
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "14px", mb: 2 }}>
        Describe your perfect AI Assistant
      </Typography>
      <TextField
        id="description"
        name="description"
        value={description ?? ""}
        label="My AI Assistant is..."
        onChange={(e) => setDescription(e.target.value)}
        multiline
        minRows={3}
        fullWidth
        px={3}
        sx={{ mt: 3 }}
      />
      <Box display="flex" justifyContent="flex-end" my={5}>
        <LoadingButton
          loadingPosition="start"
          loading={loading}
          variant="contained"
          sx={{ mr: 2 }}
          type="submit"
          disabled={loading}
          startIcon={<SaveIcon />}
          onClick={updateSettings}
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default SettingForm;
