import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Box, Paper, Typography } from "@mui/material";

import Logo from "../../vendor/logo.png";
import SignInComponent from "../../components/auth/SignIn";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 104mm;
//   height: 24mm;
//   margin-bottom: 15mm;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Wrapper
        style={{
          background: "none",
          border: "none",
          boxShadow: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img src={Logo} alt="Brand" style={{ width: 150 }} />
          <Typography sx={{ color: "white", fontSize: 20 }}>
            Chat With PDF - ExpertsCloud
          </Typography>
        </Box>
      </Wrapper>
      <Wrapper>
        <Helmet title="Sign In" />
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
