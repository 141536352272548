import { Grid, Avatar, Typography } from "@mui/material";

const QueryBox = ({ firstUpperCaseLetter, requestText }) => (
  <Grid container sx={{ padding: "20px 20px 20px 0" }}>
    <Grid item xs={2} display="flex" justifyContent="center">
      <Avatar sx={{ bgcolor: "#007FFF" }}>{firstUpperCaseLetter}</Avatar>
    </Grid>
    <Grid item xs={10} display="flex" justifyContent="flex-start" mt={1}>
      <Typography sx={{ pt: 1, whiteSpace: "pre-wrap" }}>
        {requestText}
      </Typography>
    </Grid>
  </Grid>
);

export default QueryBox;
