export const customersTableColumnDefs = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 120,
    flex: "auto",
  },
  {
    field: "name",
    minWidth: 150,
  },
  {
    field: "phone",
    minWidth: 150,
  },
  {
    headerName: "Message Count",
    field: "messageCount",
    minWidth: 130,
  },
  {
    headerName: "Active",
    field: "activeStatus",
    cellRenderer: "RenderStatusChip",
    minWidth: 100,
  },
  {
    headerName: "Last Message Date",
    field: "lastMessageDate",
    minWidth: 165,
  },
  {
    headerName: "Actions",
    cellRenderer: "ActionButtons",
    minWidth: 175,
    filter: false,
    sortable: false,
  },
];

export const documentsTableColumnDefs = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 120,
    flex: "auto",
  },
  {
    field: "name",
    minWidth: 150,
  },
  {
    headerName: "Date",
    field: "date",
    minWidth: 100,
  },
  {
    headerName: "Actions",
    cellRenderer: "ActionButtons",
    minWidth: 135,
    filter: false,
    sortable: false,
  },
];

export const requestLogsTableColumnDefs = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 120,
    flex: "auto",
  },
  {
    headerName: "Invitation Email",
    field: "user",
    // maxWidth: 200,
    flex: 1,
  },
  {
    headerName: "Referral Code",
    field: "referralCode",
    maxWidth: 150,
    flex: 3,
    // auto line-height according to the data
    wrapText: true,
    autoHeight: true,
    cellStyle: (params) => {
      return { paddingTop: "6px", paddingBottom: "5px", lineHeight: "20px" };
    },
  },
  {
    headerName: "Type",
    field: "type",
    maxWidth: 100,
    flex: 1,
  },
  {
    headerName: "Request Date",
    field: "requestDate",
    maxWidth: 200,
    flex: 1,
  },
];

export const OrganizationTableColumnDefs = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 120,
    flex: "auto",
  },
  {
    headerName: "Name",
    field: "name",
    minWidth: 100,
  },
  {
    headerName: "Phone Number",
    field: "phone",
    minWidth: 100,
  },
  {
    headerName: "Address",
    field: "address",
    minWidth: 150,
  },
  {
    headerName: "Actions",
    cellRenderer: "ActionButtons",
    minWidth: 135,
    filter: false,
    sortable: false,
  },
];

export const SubscriptionPlans = [
  {
    name: "Free Plan",
    value: 1,
  },
  {
    name: "Pro Plan",
    value: 2,
  },
];

export function valuetext(value) {
  return `${value}°C`;
}

export const placeholders = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];
export function percenttext(value) {
  return `${value * 100}%`;
}

export const percentplaceholders = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export const defaultSettingsKeys = {
  SOURCE_LIMIT: "SOURCE_LIMIT",
  SIMILARITY_SCORE: "SIMILARITY_SCORE",
  PERSONALITY_PROMPT: "PERSONALITY_PROMPT",
};
