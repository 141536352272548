import { memo } from "react";
import { Button, TextField, Grid, Stack } from "@mui/material";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const DocumentFilters = ({
  searchFilters,
  setSearchFilters,
  setCurrentPage,
  setOpen,
  openDrive,
  handleApplyFilters,
  handleCancelFilters,
}) => {
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} md={6} lg={8}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="documentName"
                fullWidth
                label="Name"
                size="medium"
                onChange={(e) =>
                  setSearchFilters({
                    ...searchFilters,
                    documentName: e.target.value.trimStart(),
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Button
              fullWidth
              variant="contained"
              disabled={Boolean(!searchFilters.documentName)}
              size="large"
              onClick={() => {
                setCurrentPage(1);
                handleApplyFilters();
              }}
            >
              Apply
            </Button>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={handleCancelFilters}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              size="large"
              onClick={() => setOpen((prev) => !prev)}
              endIcon={<FileUploadOutlinedIcon />}
            >
              PDF
            </Button>
            <Button
              fullWidth
              variant="contained"
              size="large"
              onClick={openDrive}
              endIcon={<AddToDriveIcon />}
            >
              Drive
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(DocumentFilters);
