import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { perPageItems } from "../../config/config";
import { OrganizationTable } from "./OrganizationTable";
import CustomPagination from "../../components/pagination";
import { getOrganizationListing } from "./OrganizationApiCalls";
import OrganizationDialogue from "./OrganizationDialogue";

const Organization = () => {
  const dispatch = useDispatch();
  const [organizationListing, setOrganizationListing] = useState();
  const [pageSize, setPageSize] = useState(25);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const activeRerender = useSelector((state) => state.Helpers.activeRerender);
  const paginationPageData = useSelector(
    (state) => state.Helpers.paginationPageData
  );

  useEffect(() => {
    (async () => {
      const response = await dispatch(getOrganizationListing(1, 25, null));
      setPageSize(25);
      setCurrentPage(1);
      setOrganizationListing(response);
    })();
  }, [dispatch, activeRerender]);

  const changePage = async (value) => {
    if (value !== currentPage) {
      const response = await dispatch(
        getOrganizationListing(value, pageSize, null)
      );
      setOrganizationListing(response);
      setCurrentPage(value);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet title="Organization" />
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
        alignItems="center"
      >
        <Grid item xs={12} md={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={{
              xs: 3,
              sm: 4,
              lg: 5,
            }}
          >
            <FormControl size="medium" fullWidth sx={{ minWidth: 86 }}>
              <InputLabel id="demo-simple-select-label">Per Page</InputLabel>
              <Select
                value={pageSize}
                label="Per Page:"
                onChange={(e) => changePage(e.target.value)}
              >
                {perPageItems.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {`${s.valueToshow}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            fullWidth
            onClick={() => setOpenDialogue(true)}
            variant="contained"
            size="large"
          >
            <AddIcon /> Add Organization
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <OrganizationTable rows={organizationListing} />
          <Grid item xs={12}>
            <CustomPagination
              count={paginationPageData?.totalPageCount}
              changePage={changePage}
              page={currentPage}
            />
          </Grid>
          {openDialogue && (
            <OrganizationDialogue
              dialogName="Add Organization"
              setDialogObj={() => setOpenDialogue(false)}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Organization;
