import moment from "moment";
import { setSnackBarAlert } from "../../redux/slice/AlertsSlice";
import axios from "../../utils/axios";
import { errorMessageHandler } from "../../utils/helpers";
import { appConfig } from "../../config/config";
import {
  setActiveRerender,
  setPaginationPageData,
} from "../../redux/slice/HelpersSlice";
import { setLoader } from "../../redux/slice/authSlice";

export const getDocumentListings = (
  currentPage,
  pageSize,
  searchFilters,
  signal
) => {
  return async (dispatch) => {
    try {
      const filterValues = searchFilters;
      const filters = [];
      if (filterValues?.documentName) {
        filters.push({
          column_name: "original_name",
          value: filterValues.documentName,
          type: "like",
        });
      }
      const payload = {
        page: currentPage,
        page_size: pageSize,
        filters,
      };
      dispatch(setLoader(true));
      const { data: response } = await axios.post(`/medias/listing`, payload, {
        ...(signal && { signal }),
      });
      dispatch(setLoader(false));
      if (response?.data) {
        const formattedData = response.data.map((pdf) => {
          return {
            id: pdf.id,
            name: pdf.original_name,
            date: moment(pdf.created_at).format("Do MMMM YYYY"),
            urlPath: appConfig.API_URL + pdf.full_path,
          };
        });
        const paginationPageData = {
          totalPageCount: response.total_page_count ?? 0,
          totalCount: response.total_count ?? 0,
          pageSize: response.page_size,
          pageNo: response.page,
        };
        dispatch(setPaginationPageData(paginationPageData));
        return formattedData;
      } else {
        dispatch(
          setSnackBarAlert({
            severity: "error",
            message: errorMessageHandler(response),
          })
        );
        return false;
      }
    } catch (error) {
      if (error.code !== "ERR_CANCELED") {
        console.log("error", error);
        dispatch(
          setSnackBarAlert({
            message: "Something went wrong!",
            severity: "error",
          })
        );
        return false;
      }
    }
  };
};

export const uploadDocument = (file) => {
  return async (dispatch) => {
    try {
      const payload = new FormData();
      payload.append("file", file);
      payload.append("media_type", "testing");
      dispatch(setLoader(true));
      const { data: response } = await axios.post(`/medias/`, payload);
      dispatch(setLoader(false));
      dispatch(setActiveRerender());
      if (response?.status) {
        dispatch(
          setSnackBarAlert({
            severity: "success",
            message: "Document Uploaded Successfully",
          })
        );
      } else {
        dispatch(
          setSnackBarAlert({
            severity: "error",
            message: errorMessageHandler(response),
          })
        );
        return false;
      }
    } catch (error) {
      if (error.code !== "ERR_CANCELED") {
        console.log("error", error);
        dispatch(
          setSnackBarAlert({
            message: "Something went wrong!",
            severity: "error",
          })
        );
        return false;
      }
    }
  };
};

export const deleteDocument = (id) => {
  return async (dispatch) => {
    try {
      const { data: response } = await axios.delete(`/medias/${id}`);
      if (response?.status) {
        dispatch(
          setSnackBarAlert({
            severity: "success",
            message: "Document Deleted Successfully",
          })
        );
        return true;
      } else {
        dispatch(
          setSnackBarAlert({
            severity: "error",
            message: errorMessageHandler(response),
          })
        );
      }
    } catch (error) {
      console.log("error", error);
      dispatch(
        setSnackBarAlert({
          message: "Something went wrong!",
          severity: "error",
        })
      );
    }
  };
};
export const sendGdriveCredentials = (
  file_id,
  access_token,
  file_name,
  mime_type
) => {
  console.log("Sigma");
  return async (dispatch) => {
    const body = JSON.stringify({
      file_id,
      access_token,
      file_name,
      mime_type,
    });
    console.log(body);
    try {
      const { data: response } = await axios.post(
        `api/google/download_file`,
        body
      );
      if (response?.status) {
        dispatch(
          setSnackBarAlert({
            severity: "success",
            message: "Document Uploaded Successfully",
          })
        );
        return true;
      } else {
        dispatch(
          setSnackBarAlert({
            severity: "error",
            message: errorMessageHandler(response),
          })
        );
      }
    } catch (error) {
      console.log("error", error);
      dispatch(
        setSnackBarAlert({
          message: "Something went wrong!",
          severity: "error",
        })
      );
    }
  };
};
