import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Box, Grid, Stack, Typography, Popper, Button } from "@mui/material";
import { isEmpty } from "lodash";

import {
  ContentCopyRounded as ContentCopyRoundedIcon,
  Check as CheckIcon,
} from "@mui/icons-material";

const useStyles = makeStyles({
  customPaper: {
    backgroundColor: "#F3F3F3",
    borderRadius: 5,
    whiteSpace: "pre-wrap",
    padding: "20px 20px 20px 0",
  },
});

const boldCode = (text) => {
  const regex = /\*\*(.*?)\*\*/g;
  const parts = text.split(regex);
  const components = parts.map((part, index) => {
    if (index % 2 === 1) {
      return <strong key={index}>{part}</strong>;
    }
    return part;
  });
  return components;
};

const ResponseBox = ({ chat, sources }) => {
  const classes = useStyles();
  const [isCopied, setIsCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const copyHandler = (text, event) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const renderCodeSection = () =>
    chat?.response_text?.split("```").map((part, index) => {
      if (index % 2 === 1) {
        return (
          <div key={index} style={{ position: "relative" }}>
            <SyntaxHighlighter
              language="jsx"
              style={darcula}
              key={index}
              customStyle={{ borderRadius: 5 }}
            >
              {part}
            </SyntaxHighlighter>
            <ContentCopyRoundedIcon
              sx={{
                color: "white",
                fontSize: 20,
                cursor: "pointer",
                position: "absolute",
                top: 5,
                right: 7,
                zIndex: 1,
              }}
              onClick={(event) => copyHandler(part, event)}
            />
          </div>
        );
      }
      return boldCode(part);
    });

  return (
    <Box className={classes.customPaper}>
      <Grid container>
        <Grid item xs={2} display="flex" justifyContent="center">
          <img
            src="/chat-logo.png"
            alt="logo"
            height="50px"
            width="50px"
            style={{ objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={10} mt={5}>
          <Typography component="span">{renderCodeSection()}</Typography>
          {!isEmpty(sources) ? (
            <Box mt={5}>
              {sources.map((source, index) => (
                <Button key={index}>Source {index + 1}</Button>
              ))}
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <Popper
        open={isCopied}
        anchorEl={anchorEl}
        placement="top"
        sx={{ zIndex: 1500 }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{
            backgroundColor: "#2B2B2B",
            padding: "5px 10px",
            borderRadius: 1,
          }}
        >
          <CheckIcon sx={{ fontSize: 14, color: "white" }} />
          <Typography sx={{ fontSize: 12, color: "white" }}>Copied</Typography>
        </Stack>
      </Popper>
    </Box>
  );
};

export default ResponseBox;
