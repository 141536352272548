import * as React from "react";
import { Box, Dialog, Grid, DialogContent, DialogTitle } from "@mui/material";
import CustomersInfo from "./CustomersInfo";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@mui/icons-material/Close";

export default function EmployeeDetails({ setDialogObj, data }) {
  return (
    <PerfectScrollbar>
      <Dialog onClose={setDialogObj} open={true} maxWidth="md">
        <DialogTitle>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={10} />
              <Grid item xs={2}>
                <CloseIcon
                  onClick={setDialogObj}
                  style={{
                    float: "right",
                    marginTop: 1,
                    marginBottom: 8,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <CustomersInfo customer={data.data} />
          </Box>
        </DialogContent>
      </Dialog>
    </PerfectScrollbar>
  );
}
