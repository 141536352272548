import Filters from "./Filters";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Stack } from "@mui/material";
import { isEqual } from "lodash";
import { CustomerTable } from "./CustomerTable";
import InputLabel from "@mui/material/InputLabel";
import { getCustomersData } from "./CustomersHelpers";
import { setLoader } from "../../redux/slice/authSlice";
import CustomPagination from "../../components/pagination";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  perPageItems,
  localStorageKeys,
  dataFetchType,
} from "../../config/config";

function Customers() {
  const fetchDataParam = useRef({});
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [pageSize, setPageSize] = useState(
    () => localStorage.getItem(localStorageKeys.perPage) ?? 100
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilters, setSearchFilters] = useState({
    customerName: "",
    // plan: "",
    contactNo: "",
    startDate: null,
    endDate: null,
  });
  const [searchFiltersForPayload, setSearchFiltersForPayload] = useState({});

  const getCustomers = useCallback(
    async (params) => {
      if (
        isEqual(params, fetchDataParam.current) &&
        params.type !== dataFetchType.export
      ) {
        return;
      }
      dispatch(setLoader(true));
      fetchDataParam.current = params;
      const customersData = await getCustomersData(params);
      if (params.type === dataFetchType.filter) {
        setCustomers(customersData);
      }
      dispatch(setLoader(false));
    },
    [dispatch]
  );

  const changePage = async (value) => {
    if (value !== currentPage) {
      await getCustomers({
        currentPage: parseInt(value),
        pageSize: parseInt(pageSize),
        searchFilters: searchFiltersForPayload,
        type: dataFetchType.filter,
      });
    }
    setCurrentPage(value);
  };

  useEffect(() => {
    (async () =>
      await getCustomers({
        currentPage: parseInt("1"),
        pageSize: parseInt(pageSize),
        searchFilters: searchFiltersForPayload,
        type: dataFetchType.filter,
      }))();
  }, [getCustomers, pageSize, searchFiltersForPayload]);

  const handelSelect = async (e) => {
    e.preventDefault();
    setPageSize(e.target.value);
    setCurrentPage(1);
    localStorage.setItem(localStorageKeys.perPage, e.target.value);
  };

  return (
    <>
      <Helmet title="Customers" />
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12} md={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={{
              xs: 3,
              sm: 4,
              lg: 5,
            }}
          >
            <FormControl size="medium" fullWidth sx={{ minWidth: 86 }}>
              <InputLabel id="demo-simple-select-label">Per Page</InputLabel>
              <Select
                value={pageSize}
                label="Per Page:"
                onChange={handelSelect}
              >
                {perPageItems.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {`${s.valueToshow}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={10}>
          <Filters
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            getCustomers={getCustomers}
            pageSize={pageSize}
            searchFiltersForPayload={searchFiltersForPayload}
            setSearchFiltersForPayload={setSearchFiltersForPayload}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomerTable rows={customers?.data ?? null} />
          <CustomPagination
            count={customers?.total_page_count}
            changePage={changePage}
            page={currentPage}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Customers;
