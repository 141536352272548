import axios from "../../utils/axios";
import { get } from "lodash";
import moment from "moment";
import { appConfig, defaultPlan } from "../../config/config";
import parsePhoneNumber from "libphonenumber-js/max";

export const modifyCustomerData = (customer) => {
  return {
    ...customer,
    phone: customer.phone_number ?? "-",
    messageCount: customer.message_count ?? "-",
    termsAcceptedStatus: customer.accept_toc ? "Accepted" : "Declined",
    activeStatus: customer.is_active ? "Active" : "Blocked",
    lastMessageDate: customer.last_message_date
      ? moment(customer.last_message_date).format("YYYY-MM-DD HH:mm:ss")
      : "",
    subscribedPlan: get(customer, "subscription.plan.name", false)
      ? get(customer, "subscription.plan.name", "")
      : defaultPlan.name,
  };
};

export const getCustomersData = async (params) => {
  try {
    const filterValues = params.searchFilters;
    const filters = [];
    if (filterValues?.customerName) {
      filters.push({
        column_name: "name",
        value: filterValues.customerName,
        type: "like",
      });
    }
    if (filterValues?.contactNo) {
      filters.push({
        column_name: "phone_number",
        value: filterValues.contactNo,
        type: "like",
      });
    }
    const payload = {
      page: params.currentPage,
      page_size: params.pageSize,
      filters,
    };
    const customersAPIResponse = await axios.post(
      `${appConfig.API_URL}/users`,
      payload
    );
    if (get(customersAPIResponse, "headers.content-disposition", false)) {
      const url = window.URL.createObjectURL(
        new Blob([customersAPIResponse.data])
      );
      const link = document.createElement("a");
      link.href = url;
      const fileName = `${get(
        customersAPIResponse,
        "headers.content-disposition",
        "user_list"
      )}`
        .replace(/\s/g, "")
        .split("=")[1];
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    } else {
      customersAPIResponse.data.data = customersAPIResponse.data.data.map((s) =>
        modifyCustomerData(s)
      );
      return customersAPIResponse.data;
    }
  } catch (e) {
    console.log("error", e);
    return [];
  }
};

export const fetchCustomerData = async (userId) => {
  try {
    const customerAPIResponse = await axios.get(`/users/${userId}`);
    return get(customerAPIResponse, "data.data", []);
  } catch (error) {
    return [];
  }
};

export const deleteCustomerData = async (customerData) => {
  try {
    const deleteRespose = await axios.delete(`/users/${customerData.id}`);
    return get(deleteRespose, "data.status", false);
  } catch (error) {
    return false;
  }
};

export const updateCustomerData = async (customerId, values) => {
  const payload = {
    phone_number: values.mobilePhone,
    is_active: values.isActive,
    name: values.name,
  };
  try {
    const updateResponse = await axios.patch(`/users/${customerId}`, payload);
    return get(updateResponse, "data", false);
  } catch (error) {
    return false;
  }
};

export const checkMobilePhoneValidity = (mobilePhone) => {
  const phoneNumber = parsePhoneNumber(mobilePhone);
  if (
    phoneNumber?.getType() === "MOBILE" ||
    phoneNumber?.getType() === "FIXED_LINE_OR_MOBILE"
  ) {
    return true;
  } else {
    return false;
  }
};

export const fetchCustomerChatHistory = async (userId) => {
  try {
    const customerChatAPIResponse = await axios.get(
      `/chats/get_chat_history/${userId}`
    );
    console.log(customerChatAPIResponse);
    return get(customerChatAPIResponse, "data.data", []);
  } catch (error) {
    return [];
  }
};
