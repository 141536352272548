import React, { useState } from "react";
// import { RemoveRedEye as RemoveRedEyeIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add, Edit } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { statusNameAndColor } from "../../config/config";
import DeleteConfirmationDialogue from "./DeleteConfirmationDialogue";
import OrganizationDialogue from "./OrganizationDialogue";
import OrganizationAssignUser from "./OrganizationAssignUser";

const stylesInlineTypographTable = {
  height: "100%",
  alignItems: "center",
  display: "flex",
  fontSize: "14px",
};

export const ActionButtons = (params) => {
  const [dialogObj, setDialogObj] = useState(null);
  const dialogType = {
    delete: "DELETE",
    addUser: "ADD",
    edit: "EDIT",
  };
  return (
    <>
      <Box sx={{ display: "inline" }}>
        <IconButton
          onClick={() => setDialogObj(dialogType.edit)}
          aria-label="delete"
          size="small"
        >
          <Edit />
        </IconButton>

        {/* <IconButton
          onClick={() => setDialogObj(dialogType.view)}
          aria-label="details"
          size="small"
        >
          <RemoveRedEyeIcon />
        </IconButton> */}
        <IconButton
          onClick={() => setDialogObj(dialogType.delete)}
          aria-label="delete"
          size="small"
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() => setDialogObj(dialogType.addUser)}
          aria-label="Assign Admin"
          size="small"
        >
          <Add />
        </IconButton>
        {dialogObj === dialogType.delete ? (
          <DeleteConfirmationDialogue
            data={params}
            setDialogObj={() => setDialogObj(null)}
          />
        ) : dialogObj === dialogType.edit ? (
          <OrganizationDialogue
            dialogName="Edit Organization"
            data={params.data}
            setDialogObj={() => setDialogObj(null)}
          />
        ) : dialogObj === dialogType.addUser ? (
          <OrganizationAssignUser
            data={params.data}
            setDialogObj={() => setDialogObj(null)}
          />
        ) : null}
      </Box>
    </>
  );
};

export const RenderStatusChip = ({ data }) => {
  return (
    <Typography
      sx={{ color: statusNameAndColor[data.status.toLowerCase()] }}
      style={stylesInlineTypographTable}
    >
      {data.status}
    </Typography>
  );
};
