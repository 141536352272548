import * as Yup from "yup";
import styled from "@emotion/styled";
import Switch from "@mui/material/Switch";
import { Formik } from "formik";
import { get } from "lodash";
import MuiPhoneNumber from "mui-phone-number";

import {
  Alert as MuiAlert,
  Button as MuiButton,
  CircularProgress,
  Grid,
  Paper,
  Box,
  TextField as MuiTextField,
  FormControlLabel,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  checkMobilePhoneValidity,
  updateCustomerData,
} from "./CustomersHelpers";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);
function UserInformationForm({ data, onCustomerUpdate }) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    validPhoneNumber: Yup.boolean(),
    mobilePhone: Yup.string().test(
      "checkValidity",
      "Valid Phone number is required",
      (phone, data) => {
        if (data.parent.validPhoneNumber) return true;
      }
    ),
    message_count: Yup.number().required("Required"),
  });

  const updateCustomer = async (param) => {
    const { values, setErrors, setStatus, setSubmitting } = param;
    const response = await updateCustomerData(data.id, values);
    if (response) {
      setSubmitting(false);
      onCustomerUpdate(response);
      setStatus({ sent: true });
    } else {
      setStatus({ sent: false });
      const apiErrors = get(response, "message.messages.errors", []);
      if (apiErrors.length === 0) {
        apiErrors.push({ field: "Update", message: "Operation failed." });
      }
      setErrors({
        submit: apiErrors.map((e) => `[${e.field}] ${e.message}`),
      });
      setSubmitting(false);
    }
  };
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    updateCustomer({
      values,
      setSubmitting,
      resetForm,
      setErrors,
      setStatus,
    });
  };
  const initialValues = {
    name: get(data, "name", ""),
    message_count: get(data, "message_count", 0),
    isActive: get(data, "is_active", false),
    mobilePhone: data?.phone_number ? data.phone_number : "",
    validPhoneNumber: data?.phone_number
      ? checkMobilePhoneValidity(data.phone_number)
      : false,
  };

  return (
    <Paper elevation={0} sx={{ mt: 8 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
          setFieldValue,
        }) => (
          <>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                User Updated successfully!
              </Alert>
            )}
            {status &&
              status.sent === false &&
              get(errors, "submit", []).map((e) => (
                <Alert severity="error" my={3}>
                  {e}
                </Alert>
              ))}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label="Customer Name*"
                      value={values.name === null ? "" : values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPhoneNumber
                      defaultCountry={"pk"}
                      name="mobilePhone"
                      countryCodeEditable={false}
                      label="Phone Number*"
                      placeholder="Enter your phone number"
                      value={values.mobilePhone ?? ""}
                      error={
                        touched.mobilePhone
                          ? Boolean(errors.mobilePhone)
                          : false
                      }
                      fullWidth
                      helperText={
                        touched.mobilePhone && errors.mobilePhone
                          ? errors.mobilePhone
                          : " "
                      }
                      onBlur={handleBlur}
                      variant="outlined"
                      onChange={(value, country) => {
                        setFieldValue("mobilePhone", value);
                        const isValidPhone = checkMobilePhoneValidity(value);
                        if (isValidPhone) {
                          setFieldValue("validPhoneNumber", true);
                        } else {
                          setFieldValue("validPhoneNumber", false);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          defaultChecked={Boolean(values.isActive)}
                          onChange={(e) => (values.isActive = e.target.checked)}
                        />
                      }
                      label="Active"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    mt={3}
                  >
                    Update
                  </Button>
                </Grid>
              </form>
            )}
          </>
        )}
      </Formik>
    </Paper>
  );
}

export default UserInformationForm;
