import React from "react";
import DashboardLayout from "./layouts/Dashboard";
import AuthLayout from "./layouts/Auth";
import SignIn from "./pages/auth/SignIn";
import AuthGuard from "./components/guards/AuthGuard";
import Customers from "./pages/customers/Customers";
import { Navigate } from "react-router-dom";
import RequestLogs from "./pages/requestLogs/RequestLogs";
import Documents from "./pages/documents/Documents";
import Organization from "./pages/organization";
import Settings from "./pages/settings/Settings";
// import Plans from "./pages/plans/Plans";

const routes = [
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      { element: <Navigate to="/auth/sign-in" />, index: true },
      { path: "sign-in", element: <SignIn /> },
      { path: "*", element: <Navigate to="/auth/sign-in" /> },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="/users" />, index: true },
      { path: "users", element: <Customers /> },
      { path: "invitations", element: <RequestLogs /> },
      { path: "documents", element: <Documents /> },
      { path: "organization", element: <Organization /> },
      // { path: "plans", element: <Plans /> },
      { path: "settings", element: <Settings /> },
    ],
  },
  { path: "*", element: <Navigate to="/users" /> },
];

export default routes;
