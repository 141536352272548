import { Settings } from "@mui/icons-material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
const pagesSection = [
  {
    href: "/users",
    icon: PeopleAltOutlinedIcon,
    title: "Users",
  },
  {
    href: "/documents",
    icon: TextSnippetOutlinedIcon,
    title: "Documents",
  },
  // {
  //   href: "/plans",
  //   icon: List,
  //   title: "Plans",
  // },
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
  },
  {
    href: "/organization",
    icon: CorporateFareOutlinedIcon,
    title: "Organization",
  },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
