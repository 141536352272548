import { Box, Pagination } from "@mui/material";

function CustomPagination({ count, changePage, page }) {
  const handleChange = (event, value) => {
    changePage(value);
  };
  return (
    <Box my={2} display="flex" justifyContent="flex-end">
      <Pagination
        count={count}
        page={page}
        onChange={handleChange}
        shape="rounded"
      />
    </Box>
  );
}

export default CustomPagination;
