import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { assignOrganizationAdmin } from "./OrganizationApiCalls";
import { get } from "lodash";
export default function OrganizationAssignUser(props) {
  const { data, setDialogObj } = props;
  const dispatch = useDispatch();
  const handleActive = () => {
    setDialogObj();
  };

  const initialValues = {
    name: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    email: Yup.string().email("Not an email.").required("Email is required."),
    password: Yup.string()
      .min(8, "Email must be at least 8 characters")
      .max(255)
      .required("Password is required."),
  });

  const assignAdmin = async (param) => {
    const { values, setErrors, setStatus, setSubmitting } = param;

    const response = await dispatch(assignOrganizationAdmin(values, data.id));
    if (response) {
      setSubmitting(false);
      setStatus({ sent: true });
    } else {
      setStatus({ sent: false });
      const apiErrors = get(response, "message.messages.errors", []);
      if (apiErrors.length === 0) {
        apiErrors.push({ field: "Assign", message: "Operation failed." });
      }
      setErrors({
        submit: apiErrors.map((e) => `[${e.field}] ${e.message}`),
      });
      setSubmitting(false);
    }
  };

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    if (data) {
      assignAdmin({
        values,
        setSubmitting,
        resetForm,
        setErrors,
        setStatus,
      });
    }
    handleActive();
  };
  return (
    <Dialog open={true} onClose={handleActive} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={10}>
              {`Create Admin of (${data.name})`}
            </Grid>
            <Grid item xs={2}>
              <CloseIcon
                onClick={handleActive}
                style={{
                  float: "right",
                  marginTop: 1,
                  marginBottom: 8,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Paper elevation={0} sx={{ pt: 2 }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <>
                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6} alignItems="center">
                      <Grid item xs={12}>
                        <TextField
                          name="name"
                          label="Organization Admin Name*"
                          value={values.name === null ? "" : values.name}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="email"
                          id="email"
                          type="email"
                          label="Email*"
                          value={values.email === null ? "" : values.email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="password"
                          type="password"
                          id="password"
                          label="Password*"
                          value={
                            values.password === null ? "" : values.password
                          }
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      mt={4}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        mt={3}
                      >
                        Create Admin
                      </Button>
                    </Grid>
                  </form>
                )}
              </>
            )}
          </Formik>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
