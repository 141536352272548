export const isSuperAdmin = (userType) => {
  // if type is 1 it means user is Super Admin
  return userType === 1;
};

export const isOrganizationAdmin = (userType) => {
  return userType === 2;
};

export const errorMessageHandler = (responseData) => {
  let errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e.message ?? e));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push("Something went wrong!");
  }
  return errorArray;
};
